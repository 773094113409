import './App.css';

function App() {
  const style = { 'width': "25%"  };
  return (
    <div className="App">
      <header className="App-header">
        <img style={style} src="/happyhivelogo.png" alt="logo" />
        <p>
          Happy Hive Childcare Centre Inc
        </p>

        <p>Our mission is to provide safe, nurturing childcare for the community of Kleefeld and the surrounding area.
          The focus of our centre is to promote an environment where children are able to learn, create, and grow in a fun, respectful, and inclusive setting.
          We strive to be community minded and dedicated to the families we serve.
        </p>
        <p>We are a group of community members working together to create a childcare centre in Kleefeld.
          If you have questions contact us at hello@happyhivecc.ca and&nbsp;
          <a href="https://www.facebook.com/happyhivechildcarecentre/">follow us on Facebook</a>.
        </p>
        <p> <b>Community Christmas Event</b><br/>
            December 8, 2024 from 2-6pm!<br/>
            Santa will be here for photos and two storytime and singing sessions.<br/>
            Come out for sleigh rides and a bonfire with hot chocolate for sale all afternoon.<br /><br />
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeLpmvrazSlGeiixQ1I_35n311j_0O0j1rWua4crEY1NBkR9w/viewform">Sign up here for Santa photos</a>
        </p>
      </header>
    </div>
  );
}

export default App;
